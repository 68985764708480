/** General Styles **/

.main-container {
  font-family: var(--content-font), Arial, Helvetica, sans-serif;
}

/* divides summary, 1v1, 3v3, 5v5, etc. with border */
.bracket-divider {
  border: var(--border);
  padding: 1rem;
  margin: 1rem 0;
}

.header {
  font-family: var(--title-font);
}

h2 {
  font-size: 1.6rem;
}

/* vertically centering cards within each row */
.row {
  display: flex;
  align-items: center;
}

/** Cards **/

#losses-over-time-card {
  width: 100%;
  height: 40vh;
}

.card {
  display: flex;
  justify-content: center;
  background: var(--med-gray-bg);
  box-shadow: var(--box-shadow);
  margin: 1rem auto;
}

.card-body {
  background: var(--light-gray-bg);
  font-family: var(--content-font);
  font-weight: 500;
  border: var(--border);
  border-radius: var(--border-radius-square);
}

.card-title {
  font-style: italic;
  background-color: transparent;
}

.card-header {
  display: flex;
  flex-direction: row;
  font-style: italic;
  align-items: center;
}

/* (React-Bootstrap Card type) */
.list-group-item {
  display: flex;
  background-color: var(--canvas) !important;
  text-align: left;
  width: 100%;
}

/* main stat values in summary section (total games, total losses, win rate, nemesis) */
.card-stat-value {
  color: var(--red);
  font-style: normal;
  margin: auto;
}

/* overlaid image captions (dog picture) */
.img-overlay--text {
  background-color: rgba(0, 0, 0, 0.4);
  color: var(--light-gray);
  font-family: var(--caption-font);
  font-size: 1rem;
  font-weight: bold;
  font-style: unset;
  padding: 0.3rem 0.5rem;
  border-radius: var(--border-radius-square);
}

/** Navbar **/

.navbar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--black-blue-bg);
  height: 4.5rem;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  box-shadow: var(--box-shadow);
  z-index: 99;
}

#nav-brand-text {
  color: var(--rich-yellow);
  font-family: var(--navbar-font);
  font-size: 1.5rem;
}

.container {
  height: 100%;
}

.found-dog-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: calc(100% + 32px);
  width: 100%;
}

.img-fluid {
  max-height: 75%;
  width: auto;
  object-fit: contain;
  filter: contrast(80%);
}

.navbar-link {
  text-decoration: none;
}
.navbar-link:hover #nav-brand-text {
  font-size: 1.51rem;
  text-shadow: 0 0 1px var(--bright-yellow);
}

/** Search **/

/* moving search button inside of the search-bar */

.searchbar-container {
  position: relative;
  background-color: var(--searchbar-white);
  height: 2.5rem;
  width: 100%;
  margin: auto;
  border-radius: var(--border-radius-square);
}

.searchbar-text {
  position: absolute;
  background-color: transparent;
  height: 2.5rem;
  width: 100%;
  top: 0;
  left: 0;
  border: 0;
  border-radius: 0;
}

.search-alert-container {
  margin: 5px 0 0 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.alert {
  background-color: var(--rich-yellow);
  opacity: 0.8;
  color: var(--dark-blue);
  border: none;
  box-shadow: var(--box-shadow);
  font-family: var(--caption-font);
  font-weight: 600;
  font-size: 1.2rem;
  font-style: italic;
  text-align: center;
  margin: 0;
  padding: 10px;
  border-radius: var(--border-radius-square);
}

/* styling search button, including on hover and focus */
#character-search-btn {
  position: absolute;
  background-color: transparent;
  height: 2.5rem;
  width: 4%;
  top: 0;
  right: 0;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
}

/* search disabled (realm || character missing) */
#character-search-btn.disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

#character-search-btn.disabled .fa_magnifying-glass {
  filter: sepia(50%);
}

#character-search-btn .fa_magnifying-glass {
  color: var(--search-btn-blue);
}

#character-search-btn:hover .fa_magnifying-glass {
  transform: scale(105%);
}

#character-search-btn:focus .fa_magnifying-glass {
  animation: searching 1500ms linear 0s infinite normal;
}

/* search button pulse animation on-click & until load */
@keyframes searching {
  0% {
    color: var(--search-btn-green);
    transform: scale(105%);
  }
  25% {
    transform: scale(110%);
  }
  50% {
    transform: scale(105%);
  }
  75% {
    transform: scale(100%);
  }
  100% {
    color: var(--search-btn-green);
    transform: scale(105%);
  }
}

/** Realm Selection Buttons **/

.radio-container input[type="radio"] {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  margin: 0;
  z-index: 1;
}
/* turning default radio buttons into all-in-one buttons */
.radio-container {
  position: relative;
  display: inline-block;
}

.radio-container .custom-form-check {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.radio-label {
  display: flex;
  justify-content: center;
  background: var(--dark-blue-btn-bg);
  color: var(--muted-yellow);
  font-family: var(--button-font);
  font-size: 1.5rem;
  width: 15rem;
  padding: 10px 20px;
  margin: 5px;
  border-radius: var(--border-radius-square);
  box-shadow: var(--box-shadow-btn);
  border: var(--border);
  cursor: pointer;
}

/* styling buttons on hover, when checked */
.radio-container .custom-form-check:hover + .radio-label {
  color: var(--bright-yellow);
  transform: scale(98%);
  text-shadow: 0px 0px 5px var(--bright-yellow);
}

.radio-container .custom-form-check:checked + .radio-label {
  color: var(--rich-yellow);
}

/* styling unselected button */
.anti-selected-realm {
  filter: blur(1px) saturate(50%);
}

/** Charts **/

/* Pie Charts */
.chart-card-body {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 50%;
}
.chart-padding {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* Class Win Rate Charts */
.chart-container {
  height: 100%;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.chart-container > .card-body {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/** Media Queries **/

/* tablets and smaller */
@media only screen and (max-device-height: 1025px) and (max-device-width: 769px) {
  /* aligning text to center */
  .main-container {
    text-align: center;
  }

  /* scrolling up: navbar sticks */
  .show-navbar {
    transform: translateY(0);
    transition: transform 0.3s ease-in-out;
  }

  /* scrolling down: navbar hides */
  .hide-navbar {
    position: fixed;
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
  }
}

/* smartphones and smaller */
@media only screen and (max-device-width: 450px) {
  /* keeping search button's position in searchbar & focus outline consistent */
  #character-search-btn {
    width: 11%;
  }
}
