@import url("https://fonts.googleapis.com/css2?family=Grenze+Gotisch&family=PT+Sans+Caption:wght@400;700&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&family=Roboto+Slab:wght@400;700&family=Trade+Winds&display=swap");

/*
- Imported Fonts (and styles):
Grenze Gotisch (Regular 400),
PT Sans Caption (Regular 400, Bold 700),
PT Sans (Regular 400, Regular 400 Italic, Bold 700, Bold 700 Italic),
Philosopher (Regular 400, Regular 400 Italic, Bold 700, Bold 700 Italic),
Roboto Slab (Regular 400, Bold 700),
Trade Winds (Regular 400)
*/

:root {
  --navbar-font: "Trade Winds";
  --button-font: "Trade Winds";
  --title-font: "Grenze Gotisch";
  --content-font: "PT Sans";
  --caption-font: "PT Sans Caption";

  --searchbar-white: #ffffff;
  --light-gray: #d8dae5;
  --med-gray: #a0a1a2;
  --bright-yellow: #ffff00;
  --rich-yellow: #ffd700;
  --muted-yellow: #928d09;
  --bright-orange: #ff5400;
  --orange: #e67e22;
  --red: #a80823;
  --search-btn-blue: #4169e1;
  --search-btn-green: #09c509;
  --dark-blue: rgba(17, 17, 61, 0.8);
  --dark-blue-btn-bg: linear-gradient(
    45deg,
    rgba(37, 44, 55, 1) 9%,
    rgba(51, 59, 73, 1) 47%,
    rgba(52, 59, 71, 1) 65%,
    rgba(32, 46, 73, 1) 85%
  );
  --black-blue-bg: linear-gradient(
    90deg,
    rgba(17, 17, 31, 1) 0%,
    rgba(4, 8, 20, 1) 50%,
    rgba(10, 10, 27, 1) 100%
  );
  --canvas: radial-gradient(
    circle,
    rgba(234, 234, 244, 1) 0%,
    rgba(216, 218, 229, 1) 100%
  );
  --light-gray-bg: radial-gradient(
    circle,
    rgba(214, 214, 214, 1) 0%,
    rgba(196, 198, 199, 1) 100%
  );
  --med-gray-bg: radial-gradient(
    circle,
    rgba(160, 161, 162, 1) 0%,
    rgba(180, 181, 182, 1) 100%
  );
  --border: 1.5px double rgba(0, 0, 0, 0.3);
  --border-radius-square: 3px;
  --box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  --box-shadow-btn: 0px 0px 10px 1px rgba(0, 0, 0, 0.75),
    inset 0px 0px 10px 3px rgba(0, 0, 0, 0.75);
}

body {
  margin: 0;
  font-family: var(--content-font), -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 4rem;
  background: radial-gradient(
    circle,
    rgba(224, 224, 224, 0.9) 0%,
    rgba(214, 214, 214, 0.9) 100%
  );
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
